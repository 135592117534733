import React, { useState } from "react";
import { QrReader } from "react-qr-reader";
import axios from 'axios';

const Qr = () => {
  const [result, setResult] = useState("No result");
  const [status, setStatus] = useState(0);
  let handleScan = data => {
    if (data) {
        const prefixedUrl = `https://api.forello-expo.com/public/api/verify/${data}`;
        setResult(data);

        // Make the request with Axios
        axios.get(prefixedUrl)
            .then((response) => {
                if(response.data === "sucess"){
                    setStatus(1)
                }else{
                    setStatus(0)
                }
            })
            .catch((error) => {
            // Handle error response
                setStatus(0);
        });
    }
  };

  let handleError = err => {
    // alert(err);
  };
  return (
    <div>
      <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: "100%" }}
        facingMode="environment"
      />
      <p>{result}</p>
    </div>
  );
}

export default Qr;