import React, {useState, useEffect} from "react"
import { useParams, NavLink } from "react-router-dom/cjs/react-router-dom";
import styled from "styled-components";
import { motion } from 'framer-motion'
import { getTimelineFromApi, getExhibitorsFromApi } from '../api/api'

const FrameChild = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 77px;
  flex-shrink: 0;
  object-fit: cover;
  z-index: 0;
`;
const Div = styled.div`
  width: 131px;
  position: relative;
  text-transform: uppercase;
  font-weight: 800;
  display: inline-block;
  flex-shrink: 0;
`;
const Div1 = styled.div`
  width: 76px;
  position: relative;
  text-transform: uppercase;
  font-weight: 800;
  color: var(--secondary);
  display: inline-block;
  flex-shrink: 0;
`;
const Animation1 = styled.div`
  align-self: stretch;
  position: relative;
  text-transform: uppercase;
`;
const EthiopianAirlinesFriends = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-mini);
  text-transform: uppercase;
  font-weight: 800;
  color: var(--white);
`;
const AnimationParent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: 10px;
  color: #057d39;
`;
const Parent1 = styled.div`
  align-self: stretch;
  background-color: var(--text);
  height: 60px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0px;
  box-sizing: border-box;
  z-index: 1;
`;
const FexPngWhite1Icon = styled.img`
  width: 78px;
  position: absolute;
  margin: 0 !important;
  top: 373px;
  left: 638px;
  height: 55px;
  object-fit: cover;
  z-index: 2;
`;
const FrameParent = styled.div`
  align-self: stretch;
  width: 746px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  z-index: 1;
`;
const FrameItem = styled.img`
  width: 278px;
  position: relative;
  height: 77px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
`;
const Solidis = styled.div`
  // position: absolute;
  // top: 14.1px;
  // left: 14.1px;
  text-transform: uppercase;
  font-weight: 800;
  display: inline-block;
  width: 240.8px;
`;
const Stands = styled.div`
  // position: absolute;
  // top: 91.6px;
  // left: 14.1px;
  font-size: var(--font-size-mini);
  display: inline-block;
  width: 240.8px;
`;
const EthiopianAirlinesFriendsTrParent = styled.div`
  align-self: stretch;
  padding: 18px;
  flex: 1;
  position: relative;
  background-color: var(--third);
  overflow: hidden;
`;
const SolidisParent = styled.div`
  position: absolute;
  top: 0.3px;
  left: 0px;
  background-color: var(--third);
  width: 278px;
  height: 121.7px;
  overflow: hidden;
`;
const SloganDeLaFoireInternationParent = styled.div`
  position: absolute;
  top: -0.3px;
  left: 0px;
  background-color: var(--third);
  width: 278px;
  height: 121.7px;
  overflow: hidden;
`;
const FrameContainer = styled.div`
  width: 278px;
  flex: 1;
  background-color: var(--color-darkorange);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
`;
const FrameInner = styled.div`
  width: 278px;
  position: relative;
  background-color: #057d39;
  height: 60px;
  overflow: hidden;
  flex-shrink: 0;
`;
const FrameGroup = styled.div`
  align-self: stretch;
  background-color: var(--third);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 0;
  text-align: left;
  color: var(--secondary);
`;
const RegieRoot = styled.div`
  width: 1024px;
  max-width: 100%;
  height: 512px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--paragraph-size);
  color: var(--primary);
  font-family: var(--paragraph);
`;

const Regie = () => {

    // Initialize state with current time

  const [time, setTime] = useState(new Date());
  const [timeline, setTimeline] = useState([])
  const [timelines, setTimelines] = useState([])
  const [exhibitors, setExhibitors] = useState([])
  const [currentTimeline, setCurrentTimeline] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);


  useEffect(() => {
    // Function to update time
    const updateClock = () => {
      setTime(new Date());
    };

    // Set interval to update clock every second
    const intervalId = setInterval(updateClock, 10000); // 1000 ms = 1 second

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Format time as a string
  const formattedTime = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  useEffect(() => {
    const fetchTimeline = async () => {
        const data = await getTimelineFromApi();
        if (data !== undefined) {
            setTimeline(data);
        }
    };

    const fetchExhibitors = async () => {
        const data = await getExhibitorsFromApi();
        if (data !== undefined) {
            setExhibitors(data);
        }
    };

    // Initial fetch of exhibitors
    fetchExhibitors();

    // Interval for updating exhibitors every 5 seconds
    const exhibitorsIntervalId = setInterval(fetchExhibitors, 7000);

    // Initial fetch of timeline
    fetchTimeline();

    return () => {
        clearInterval(exhibitorsIntervalId);
    };
}, []);

useEffect(() => {
    if (timeline.length === 0) return;

    const updateCurrentTimeline = () => {
        setCurrentTimeline(timeline[currentIndex]);
        setCurrentIndex((prevIndex) => {
            const newIndex = (prevIndex + 1) % timeline.length;
            if (newIndex === 0) {
                // Fetch timeline data again after a full iteration
                fetchTimeline();
            }
            return newIndex;
        });
    };

    // Interval for updating current timeline every 3 seconds
    const currentTimelineIntervalId = setInterval(updateCurrentTimeline, 3000);

    return () => clearInterval(currentTimelineIntervalId);
}, [timeline, currentIndex]);

const fetchTimeline = async () => {
    const data = await getTimelineFromApi();
    if (data !== undefined) {
        setTimeline(data);
        setCurrentIndex(0); // Reset index to start the iteration from the beginning
    }
};

const formatTime = (datetimeString) => {
  const date = new Date(datetimeString);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

console.log(currentTimeline)

  return (
    <RegieRoot>
      <FrameParent>
        <FrameChild alt="" src="/frame72.png" />
        <Parent1>
          <Div>{formattedTime}</Div>
          <Div1>{currentTimeline?.start ? formatTime(currentTimeline.start) : 'Loading...'}</Div1>
          <AnimationParent>
            <Animation1>{currentTimeline?.eventtypes ? currentTimeline.eventtypes : 'Loading...'}</Animation1>
            <EthiopianAirlinesFriends>
              {currentTimeline?.name ? currentTimeline.name : 'Loading...'}
            </EthiopianAirlinesFriends>
          </AnimationParent>
        </Parent1>
        <FexPngWhite1Icon alt="" src="/fex-png-white-1@2x.png" />
      </FrameParent>
      <FrameGroup>
        <FrameItem alt="" src="/frame-72@2x.png" />
        <FrameContainer>
          {
            exhibitors.map((item, id)=>{
              return(
                <EthiopianAirlinesFriendsTrParent>
                  <Solidis>{item.name}</Solidis>
                  <Stands>Stands : {item.stands}</Stands>
                </EthiopianAirlinesFriendsTrParent>
              )
            })
          }
        </FrameContainer>
        <FrameInner />
      </FrameGroup>
    </RegieRoot>
  );
};

export default Regie;
