import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    body {
      margin: 0; line-height: normal;
    }

    input {
      outline : 0;
      border: none;
    }
    
    input:focus {
      outline : 0;
      border: none;
    }

:root {

  .leaflet-container {
    width: 100%;
    height: 100vh;
  }

  .stand {
    background-color: #999;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .sectors::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .sectors {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .exhibitors::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .exhibitors {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  /* fonts */
--font-montserrat: Montserrat;
--paragraph: Inter;

/* font sizes */
--font-size-31xl: 50px;
--paragraph-size: 19px;
--font-size-11xl: 30px;
--font-size-127xl-3: 146.3px;
--font-size-6xl: 25px;
--font-size-mini: 15px;
--font-size-21xl: 40px;
--font-size-base-2: 16.2px;
--font-size-8xl: 27px;
--font-size-lg: 18px;
--font-size-10xl-7: 29.7px;
--font-size-sm-1: 13.1px;
--font-size-xl-9: 20.9px;
--font-size-base-7: 15.7px;
--font-size-mini-4: 14.4px;
--font-size-15xl-3: 34.3px;
--font-size-13xl: 32px;
--font-size-26xl-3: 45.3px;

/* Colors */
--third: #ebebeb;
--white: #fff;
--secondary: #423e3b;
--color-gainsboro-100: #d9d9d9;
--color-gainsboro-200: #d8d8d8;
--color-gray: #092132;
--color-orange-100: #febd1c;
--color-steelblue: #0082ab;
--text: #000;
--color-darkorange: #f68122;
--primary: #f4842d;
--color-darkgray: #959595;

/* Gaps */
--gap-xs: 12px;
--gap-11xl: 30px;
--gap-5xl: 24px;
--gap-18xl: 37px;
--gap-xl: 20px;
--gap-3xs: 10px;
--gap-sm: 14px;
--gap-13xl: 32px;
--gap-10xl: 29px;
--gap-21xl: 40px;
--gap-4xs: 9px;
--gap-lg: 18px;
--gap-6xs-7: 6.7px;
--gap-8xl: 27px;
--gap-5xs: 8px;
--gap-xl-2: 20.2px;

/* Paddings */
--padding-55xl: 74px;
--padding-31xl: 50px;
--padding-9xs: 4px;
--padding-21xl: 40px;
--padding-mini: 15px;
--padding-3xs: 10px;
--padding-6xl: 25px;
--padding-81xl: 100px;
--padding-11xl: 30px;
--padding-xl: 20px;
--padding-16xl: 35px;
--padding-4xs: 9px;
--padding-7xl-3: 26.3px;
--padding-3xl-7: 22.7px;
--padding-35xl-1: 54.1px;
--padding-41xl: 60px;
--padding-14xl-7: 33.7px;

/* Border radiuses */
--br-mini: 15px;
--br-7xs: 6px;
--br-sm-1: 13.1px;
--br-6xs-7: 6.7px;
--br-base-1: 15.1px;
--br-10xs-8: 2.8px;
--br-9xl-6: 28.6px;
--br-6xl-3: 25.3px;

}
`;