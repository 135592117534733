const axios = require('axios').default;

const api = "https://api.forello-expo.com/public/api";

// ----------------------

export function getCategoriesFromApi (){
    const url = api+"/sectors";

    return axios({
            method: 'get',
            url: url,
            mode: 'no-cors',
          })
            .then((response) => response.data)
            .catch((error) => console.error(error))
}
export function getCompaniesFromApi (category, keyword){
    const url = api+"/exhibitors/"+category+"/"+keyword;
    return axios({
        method: 'get',
        url: url,
        mode: 'no-cors',
    })
        .then((response) => response.data)
        .catch((error) => console.error(error))
}

export function getTimelineFromApi(){
  const url = api+"/upTime";
  return axios({
      method: 'get',
      url: url,
      mode: 'no-cors',
  })
      .then((response) => response.data)
      .catch((error) => console.error(error))
}

export function getExhibitorsFromApi(){
  const url = api+"/randomexhibitors";
  return axios({
      method: 'get',
      url: url,
      mode: 'no-cors',
  })
      .then((response) => response.data)
      .catch((error) => console.error(error))
}
export function getSingleCompanyFromApi (id){
    const url = api+"/exhibitor/"+id;
    return axios({
        method: 'get',
        url: url,
        mode: 'no-cors',
      })
        .then((response) => response.data[0])
        .catch((error) => console.error(error))
}