import React from 'react';
import { Route, Switch, useLocation } from "react-router"
import { ThemeProvider } from "styled-components"
import { lightTheme } from "./components/Themes"
import GlobalStyle from "./globalStyles"


// Components
import { AnimatePresence } from "framer-motion";
import Main from './views/Main';
import Regie from './views/Regie';
// import Map from './views/Map';
import Qr from './views/Qr';
// import AboutPage from './views/AboutPage';
// import BlogPage from './views/BlogPage';
// import Salon from "./views/Salon";
// import BlogDetail from "./views/BlogDetail"
// import Attendee from './views/Attendee';
// import InDev from './views/InDev';
// import Annuaire from "./views/Annuaire"
// import DetailAn from './views/DetailAn';
// import TimelinePage from './views/TimelinePage';
// import TimelineNext from "./views/TimelineNext"
// import LogoComponent from "./subComponents/LogoComponent";
// import SoundBar from "./subComponents/SoundBar";


function App() {
  const location = useLocation();

  return(
    <>
      <GlobalStyle />
      <ThemeProvider theme={lightTheme}>
        {/* <SoundBar /> */}
        {/* For framer-motion animation on page change! */}
        <AnimatePresence mode='wait'>
          <Switch  location={location} key={location.pathname}>
            <Route exact path="/" component={Regie}/>
            {/* <Route exact path="/map" component={Map}/> */}
            {/* <Route exact path="/regie/1" component={Regie}/> */}
            <Route exact path="/qr" component={Qr}/>
            {/* <Route path="/about" component={AboutPage}/>
            <Route exact path="/blog" component={BlogPage}/>
            <Route exact path="/invitation" component={Attendee}/>
            <Route exact path={`/blog/:id`} component={BlogDetail}/>
            <Route exact path={`/annuaire/:initCategorie`} component={Annuaire}/>
            <Route exact path={`/annuaire/detail/:id`} component={DetailAn}/>
            <Route exact path="/timeline" component={TimelinePage}/>
            <Route exact path={`/timeline/:id`} component={TimelineNext}/> */}
          </Switch>
        </AnimatePresence>
      </ThemeProvider>
    </>
  )
}

export default App

