import React, {useState, useEffect} from "react"
import { useParams, NavLink } from "react-router-dom/cjs/react-router-dom";
import styled from "styled-components";
import { motion } from 'framer-motion'
import { getCompaniesFromApi, getCategoriesFromApi, getSingleCompanyFromApi } from '../api/api'

// const icon = L.icon({ iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png" });

const FrameChild = styled.div`
  width: 55px;
  box-shadow: 0px 7.1px 17.64px rgba(0, 0, 0, 0.2);
  border-radius: var(--br-xs);
  background-color: var(--white);
  height: 55px;
`;
const Go = styled.div`
  width: 122.9px;
  position: relative;
  display: inline-block;
  flex-shrink: 0;
`;
const GoWrapper = styled.div`
  align-self: stretch;
  width: 59.8px;
  background-color: var(--primary);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 13.1px;
  box-sizing: border-box;
  cursor: pointer;
`;
const FrameWrapper = styled.div`
  width: 449px;
  box-shadow: 0px 19.7px 32.87px -6.57px rgba(0, 0, 0, 0.1);
  border-radius: var(--br-xs);
  background-color: var(--white);
  height: 55px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-width: 262.95751953125px;
`;
const FrameParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-21xl);
  z-index: 2;
`;
const WelcomeSectionChild = styled.div`
  width: 389px;
  position: absolute;
  margin: 0 !important;
  top: 0px;
  left: 0px;
  background: linear-gradient(
    180deg,
    #ebebeb,
    #ebebeb 71.5%,
    rgba(235, 235, 235, 0)
  );
  height: 161px;
  z-index: 1;
`;
const SloganDeLa = styled.div`
  align-self: stretch;
  position: relative;
  text-transform: uppercase;
`;
const Stands = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-sm-4);
`;
const SloganDeLaFoireInternationParent = styled.div`
  align-self: stretch;
  box-shadow: 0px 7.1px 17.64px rgba(0, 0, 0, 0.2);
  border-radius: 10.59px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mini-1);
  gap: var(--gap-4xs-5);
`;
const FrameGroup = styled.div`
  align-self: stretch;
  height: 654px;
  overflow-y: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-21xl) 0px 0px;
  box-sizing: border-box;
  gap: 21px;
  z-index: 0;
  text-align: left;
  font-size: var(--paragraph-size);
  color: var(--secondary);
`;
const WelcomeSection = styled.div`
  width: 389px;
  margin: 0 !important;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 768px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-41xl) var(--padding-41xl) 0px;
  box-sizing: border-box;
  z-index: 4;
`;
const FimmapChild = styled.div`
  width: 86px;
  position: absolute;
  margin: 0 !important;
  top: 37px;
  left: 604px;
  background: linear-gradient(
    90deg,
    #ebebeb,
    #ebebeb 28.5%,
    rgba(235, 235, 235, 0)
  );
  height: 100px;
  z-index: 3;
`;
const Leadership = styled.div`
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
`;
const LeadershipWrapper = styled.div`
  box-shadow: 0px 0px 17px 7px rgba(0, 0, 0, 0.05);
  border-radius: var(--br-xs);
  background-color: var(--white);
  height: 55px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xl-7);
  box-sizing: border-box;
  cursor: pointer;
`;
const WelcomeSection1 = styled.div`
  width: calc(100% - 604px);
  margin: 0 !important;
  position: absolute;
  top: 39px;
  right: 0px;
  left: 604px;
  height: 96px;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px var(--padding-81xl);
  box-sizing: border-box;
  gap: var(--gap-xl);
  z-index: 2;
  text-align: left;
  font-size: var(--font-size-mini-4);
  color: var(--primary);
`;
const Div = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 508.5px);
  top: 0px;
  right: 508.5px;
  bottom: 0px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div1 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 473.1px);
  top: 0px;
  right: 436.8px;
  bottom: 0px;
  left: 36.3px;
  border-radius: 2.79px;
 
`;
const Div2 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 508.5px);
  top: 0px;
  right: 326.9px;
  bottom: 0px;
  left: 181.6px;
  border-radius: 2.79px;
 
`;
const Div3 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 527.1px);
  top: 0px;
  right: 290.5px;
  bottom: 0px;
  left: 236.6px;
  border-radius: 2.79px;
 
`;
const Div4 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 508.5px);
  top: 0px;
  right: 254.2px;
  bottom: 0px;
  left: 254.3px;
  border-radius: 2.79px;
 
`;
const Div5 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 508.5px);
  top: 0px;
  right: 217.9px;
  bottom: 0px;
  left: 290.6px;
  border-radius: 2.79px;
 
`;
const Div6 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 508.5px);
  top: 0px;
  right: 181.6px;
  bottom: 0px;
  left: 326.9px;
  border-radius: 2.79px;
 
`;
const Div7 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 363.2px);
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 363.2px;
  border-radius: 2.79px;
 
`;
const Parent1 = styled.div`
  position: absolute;
  top: calc(50% - 198.5px);
  left: calc(50% - 106.5px);
  width: 542px;
  height: 18.6px;
  overflow: hidden;
`;
const Div8 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 418.2px);
  top: 0px;
  right: 418.2px;
  bottom: 0px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div9 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 454.5px);
  top: 0px;
  right: 363.2px;
  bottom: 0px;
  left: 91.3px;
  border-radius: 2.79px;
 
`;
const Div10 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 471.3px);
  top: 0px;
  right: 289.7px;
  bottom: 0px;
  left: 181.6px;
  border-radius: 2.79px;
 
`;
const Div11 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 471.3px);
  top: 0px;
  right: 235.7px;
  bottom: 0px;
  left: 235.6px;
  border-radius: 2.79px;
 
`;
const Div12 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 471.3px);
  top: 0px;
  right: 126.7px;
  bottom: 0px;
  left: 344.6px;
  border-radius: 2.79px;
 
`;
const Div13 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 434.9px);
  top: 0px;
  right: 71.7px;
  bottom: 0px;
  left: 363.2px;
  border-radius: 2.79px;
 
`;
const Div14 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 435.9px);
  top: 0px;
  right: 308.3px;
  bottom: 19.6px;
  left: 127.6px;
  border-radius: 2.79px;
 
`;
const Div15 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 472.2px);
  top: 18.6px;
  right: 344.6px;
  bottom: 0px;
  left: 127.6px;
  border-radius: 2.79px;
 
`;
const Div16 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 454.5px);
  top: 0px;
  right: 200.2px;
  bottom: 19.6px;
  left: 254.3px;
  border-radius: 2.79px;
 
`;
const Div17 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 453.6px);
  top: 0px;
  right: 163.9px;
  bottom: 19.6px;
  left: 289.7px;
  border-radius: 2.79px;
 
`;
const Div18 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 471.3px);
  top: 18.6px;
  right: 217px;
  bottom: 0px;
  left: 254.3px;
  border-radius: 2.79px;
 
`;
const Div19 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 473.1px);
  top: 18.6px;
  right: 37.2px;
  bottom: 0px;
  left: 435.9px;
  border-radius: 2.79px;
 
`;
const Div20 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 435.9px);
  top: 0px;
  right: 0px;
  bottom: 19.6px;
  left: 435.9px;
  border-radius: 2.79px;
 
`;
const Div21 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 452.6px);
  top: 18.6px;
  right: 0px;
  bottom: 0px;
  left: 452.6px;
  border-radius: 2.79px;
 
`;
const Div22 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 473.1px);
  top: 18.6px;
  right: 200.2px;
  bottom: 0px;
  left: 272.9px;
  border-radius: 2.79px;
 
`;
const Div23 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 452.6px);
  top: 18.6px;
  right: 162.9px;
  bottom: 0px;
  left: 289.7px;
  border-radius: 2.79px;
 
`;
const Group = styled.div`
  position: absolute;
  top: calc(50% - 156.6px);
  left: calc(50% - 70.1px);
  width: 487.1px;
  height: 35.4px;
  overflow: hidden;
`;
const Div24 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 472.2px);
  top: 18.6px;
  right: 472.2px;
  bottom: 0px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div25 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 472.2px);
  top: 18.6px;
  right: 418.2px;
  bottom: 0px;
  left: 54px;
  border-radius: 2.79px;
 
`;
const Div26 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 472.2px);
  top: 0px;
  right: 418.2px;
  bottom: 19.6px;
  left: 54px;
  border-radius: 2.79px;
 
`;
const Div27 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 472.2px);
  top: 0px;
  right: 472.2px;
  bottom: 19.6px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div28 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 453.6px);
  top: 0px;
  right: 435.9px;
  bottom: 0px;
  left: 17.7px;
  border-radius: 2.79px;
 
`;
const Div29 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 418.2px);
  top: 0px;
  right: 326.9px;
  bottom: 0px;
  left: 91.3px;
  border-radius: 2.79px;
 
`;
const Div30 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 452.6px);
  top: 0px;
  right: 289.6px;
  bottom: 19.6px;
  left: 163px;
  border-radius: 2.79px;
 
`;
const Div31 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 452.6px);
  top: 18.6px;
  right: 289.6px;
  bottom: 0px;
  left: 163px;
  border-radius: 2.79px;
 
`;
const Div32 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 452.6px);
  top: 18.6px;
  right: 217px;
  bottom: 0px;
  left: 235.6px;
  border-radius: 2.79px;
 
`;
const Div33 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 471.3px);
  top: 0px;
  right: 235.7px;
  bottom: 19.6px;
  left: 235.6px;
  border-radius: 2.79px;
 
`;
const Div34 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 471.3px);
  top: 18.6px;
  right: 181.6px;
  bottom: 0px;
  left: 289.7px;
  border-radius: 2.79px;
 
`;
const Div35 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 471.3px);
  top: 18.6px;
  right: 163px;
  bottom: 0px;
  left: 308.3px;
  border-radius: 2.79px;
 
`;
const Div36 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 471.3px);
  top: 0px;
  right: 163px;
  bottom: 19.6px;
  left: 308.3px;
  border-radius: 2.79px;
 
`;
const Div37 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 471.3px);
  top: 0px;
  right: 181.6px;
  bottom: 19.6px;
  left: 289.7px;
  border-radius: 2.79px;
 
`;
const Div38 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 472.2px);
  top: 18.6px;
  right: 109px;
  bottom: 0px;
  left: 363.2px;
  border-radius: 2.79px;
 
`;
const Div39 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 472.2px);
  top: 0px;
  right: 109px;
  bottom: 19.6px;
  left: 363.2px;
  border-radius: 2.79px;
 
`;
const Div40 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 471.3px);
  top: 0px;
  right: 126.7px;
  bottom: 19.6px;
  left: 344.6px;
  border-radius: 2.79px;
 
`;
const Div41 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 471.3px);
  top: 18.6px;
  right: 126.7px;
  bottom: 0px;
  left: 344.6px;
  border-radius: 2.79px;
 
`;
const Div42 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 470.3px);
  top: 0px;
  right: 71.7px;
  bottom: 19.6px;
  left: 398.6px;
  border-radius: 2.79px;
 
`;
const Div43 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 470.3px);
  top: 18.6px;
  right: 71.7px;
  bottom: 0px;
  left: 398.6px;
  border-radius: 2.79px;
 
`;
const Div44 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 472.2px);
  top: 0px;
  right: 91.3px;
  bottom: 19.6px;
  left: 380.9px;
  border-radius: 2.79px;
 
`;
const Div45 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 472.2px);
  top: 18.6px;
  right: 91.3px;
  bottom: 0px;
  left: 380.9px;
  border-radius: 2.79px;
 
`;
const Div46 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 473.1px);
  top: 0px;
  right: 37.2px;
  bottom: 19.6px;
  left: 435.9px;
  border-radius: 2.79px;
 
`;
const Div47 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 471.3px);
  top: 0px;
  right: 0px;
  bottom: 19.6px;
  left: 471.3px;
  border-radius: 2.79px;
 
`;
const Div48 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 471.3px);
  top: 18.6px;
  right: 0px;
  bottom: 0px;
  left: 471.3px;
  border-radius: 2.79px;
 
`;
const Div49 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 471.3px);
  top: 0px;
  right: 18.7px;
  bottom: 19.6px;
  left: 452.6px;
  border-radius: 2.79px;
 
`;
const Div50 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 471.3px);
  top: 18.6px;
  right: 18.7px;
  bottom: 0px;
  left: 452.6px;
  border-radius: 2.79px;
 
`;
const Container = styled.div`
  position: absolute;
  top: calc(50% - 102.6px);
  left: calc(50% - 70.1px);
  width: 487.1px;
  height: 35.4px;
  overflow: hidden;
`;
const Div51 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 473.1px);
  top: 0px;
  right: 37.2px;
  bottom: 0px;
  left: 435.9px;
  border-radius: 2.79px;
 
`;
const Div52 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 452.6px);
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 452.6px;
  border-radius: 2.79px;
 
`;
const Div53 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 453.6px);
  top: 0px;
  right: 109px;
  bottom: 19.6px;
  left: 344.6px;
  border-radius: 2.79px;
 
`;
const Div54 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 453.6px);
  top: 18.6px;
  right: 109px;
  bottom: 0px;
  left: 344.6px;
  border-radius: 2.79px;
 
`;
const Div55 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 452.6px);
  top: 18.6px;
  right: 71.7px;
  bottom: 0px;
  left: 380.9px;
  border-radius: 2.79px;
 
`;
const Div56 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 452.6px);
  top: 0px;
  right: 217px;
  bottom: 0px;
  left: 235.6px;
  border-radius: 2.79px;
 
`;
const Div57 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 435.9px);
  top: 0px;
  right: 163px;
  bottom: 0px;
  left: 272.9px;
  border-radius: 2.79px;
 
`;
const Div58 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 452.6px);
  top: 0px;
  right: 289.6px;
  bottom: 0px;
  left: 163px;
  border-radius: 2.79px;
 
`;
const Div59 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 453.6px);
  top: 0px;
  right: 326.9px;
  bottom: 19.6px;
  left: 126.7px;
  border-radius: 2.79px;
 
`;
const Div60 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 453.6px);
  top: 18.6px;
  right: 326.9px;
  bottom: 0px;
  left: 126.7px;
  border-radius: 2.79px;
 
`;
const Div61 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 418.2px);
  top: 0px;
  right: 418.2px;
  bottom: 19.6px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div62 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 435.9px);
  top: 18.6px;
  right: 435.9px;
  bottom: 0px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Parent2 = styled.div`
  position: absolute;
  top: calc(50% - 48.5px);
  left: calc(50% - 70.1px);
  width: 487.1px;
  height: 35.4px;
  overflow: hidden;
`;
const Div63 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 90.4px);
  top: 0px;
  right: 90.4px;
  bottom: 0px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div64 = styled.div`
  position: absolute;
  height: calc(100% - 28px);
  width: calc(100% - 90.4px);
  top: 0px;
  right: 0.1px;
  bottom: 28px;
  left: 90.3px;
  border-radius: 2.79px;
 
`;
const Div65 = styled.div`
  position: absolute;
  height: calc(100% - 27.1px);
  width: calc(100% - 90.4px);
  top: 27px;
  right: 0.1px;
  bottom: 0.1px;
  left: 90.3px;
  border-radius: 2.79px;
 
`;
const Parent3 = styled.div`
  position: absolute;
  top: calc(50% + 12px);
  left: calc(50% - 70.1px);
  width: 159.3px;
  height: 52.2px;
  overflow: hidden;
`;
const Div66 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 452.6px);
  top: 0px;
  right: 452.6px;
  bottom: 19.6px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div67 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 452.6px);
  top: 18.6px;
  right: 452.6px;
  bottom: 0px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div68 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 455.4px);
  top: 0px;
  right: 418.1px;
  bottom: 19.6px;
  left: 37.3px;
  border-radius: 2.79px;
 
`;
const Div69 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 473.1px);
  top: 18.6px;
  right: 435.8px;
  bottom: 0px;
  left: 37.3px;
  border-radius: 2.79px;
 
`;
const Div70 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 472.2px);
  top: 0px;
  right: 380.9px;
  bottom: 19.6px;
  left: 91.3px;
  border-radius: 2.79px;
 
`;
const Div71 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 472.2px);
  top: 18.6px;
  right: 380.9px;
  bottom: 0px;
  left: 91.3px;
  border-radius: 2.79px;
 
`;
const Div72 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 435.9px);
  top: 0px;
  right: 326.9px;
  bottom: 0px;
  left: 109px;
  border-radius: 2.79px;
 
`;
const Div73 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 473.1px);
  top: 0px;
  right: 200.2px;
  bottom: 19.6px;
  left: 272.9px;
  border-radius: 2.79px;
 
`;
const Div74 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 452.6px);
  top: 0px;
  right: 162.9px;
  bottom: 19.6px;
  left: 289.7px;
  border-radius: 2.79px;
 
`;
const Div75 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 452.6px);
  top: 0px;
  right: 71.7px;
  bottom: 19.6px;
  left: 380.9px;
  border-radius: 2.79px;
 
`;
const Parent4 = styled.div`
  position: absolute;
  top: calc(50% + 91.2px);
  left: calc(50% - 70.1px);
  width: 487.1px;
  height: 35.4px;
  overflow: hidden;
`;
const Div76 = styled.div`
  position: absolute;
  height: calc(100% - 18.6px);
  width: calc(100% - 455.4px);
  top: 18.6px;
  right: 418.1px;
  bottom: 0px;
  left: 37.3px;
  border-radius: 2.79px;
 
`;
const Div77 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 435.9px);
  top: 0px;
  right: 344.6px;
  bottom: 0px;
  left: 91.3px;
  border-radius: 2.79px;
 
`;
const Div78 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 472.2px);
  top: 0px;
  right: 326.9px;
  bottom: 0px;
  left: 145.3px;
  border-radius: 2.79px;
 
`;
const Div79 = styled.div`
  position: absolute;
  height: calc(100% - 19.6px);
  width: calc(100% - 452.6px);
  top: 0px;
  right: 217px;
  bottom: 19.6px;
  left: 235.6px;
  border-radius: 2.79px;
 
`;
const Div80 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 452.6px);
  top: 0px;
  right: 162.9px;
  bottom: 0px;
  left: 289.7px;
  border-radius: 2.79px;
 
`;
const Div81 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 416.3px);
  top: 0px;
  right: 71.7px;
  bottom: 0px;
  left: 344.6px;
  border-radius: 2.79px;
 
`;
const Parent5 = styled.div`
  position: absolute;
  top: calc(50% + 145.2px);
  left: calc(50% - 70.1px);
  width: 487.1px;
  height: 35.4px;
  overflow: hidden;
`;
const Div82 = styled.div`
  position: absolute;
  height: calc(100% - 18.7px);
  width: calc(100% - 472.2px);
  top: 0px;
  right: 472.2px;
  bottom: 18.7px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div83 = styled.div`
  position: absolute;
  height: calc(100% - 18.7px);
  width: calc(100% - 472.2px);
  top: 18.6px;
  right: 472.2px;
  bottom: 0.1px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div84 = styled.div`
  position: absolute;
  height: calc(100% - 18.7px);
  width: calc(100% - 453.6px);
  top: 0px;
  right: 435.9px;
  bottom: 18.7px;
  left: 17.7px;
  border-radius: 2.79px;
 
`;
const Div85 = styled.div`
  position: absolute;
  height: calc(100% - 18.7px);
  width: calc(100% - 470.3px);
  top: 18.6px;
  right: 452.6px;
  bottom: 0.1px;
  left: 17.7px;
  border-radius: 2.79px;
 
`;
const Div86 = styled.div`
  position: absolute;
  height: calc(100% - 18.7px);
  width: calc(100% - 455.4px);
  top: 18.6px;
  right: 418.1px;
  bottom: 0.1px;
  left: 37.3px;
  border-radius: 2.79px;
 
`;
const Div87 = styled.div`
  position: absolute;
  height: calc(100% - 18.7px);
  width: calc(100% - 472.2px);
  top: 0px;
  right: 418.2px;
  bottom: 18.7px;
  left: 54px;
  border-radius: 2.79px;
 
`;
const Div88 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 380.9px);
  top: 0px;
  right: 289.6px;
  bottom: 0px;
  left: 91.3px;
  border-radius: 2.79px;
 
`;
const Div89 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 398.6px);
  top: 0px;
  right: 163px;
  bottom: 0px;
  left: 235.6px;
  border-radius: 2.79px;
 
`;
const Div90 = styled.div`
  position: absolute;
  height: calc(100% - 18.7px);
  width: calc(100% - 453.6px);
  top: 18.6px;
  right: 109px;
  bottom: 0.1px;
  left: 344.6px;
  border-radius: 2.79px;
 
`;
const Div91 = styled.div`
  position: absolute;
  height: calc(100% - 18.7px);
  width: calc(100% - 472.2px);
  top: 0px;
  right: 109px;
  bottom: 18.7px;
  left: 363.2px;
  border-radius: 2.79px;
 
`;
const Div92 = styled.div`
  position: absolute;
  height: calc(100% - 18.7px);
  width: calc(100% - 471.3px);
  top: 0px;
  right: 126.7px;
  bottom: 18.7px;
  left: 344.6px;
  border-radius: 2.79px;
 
`;
const Div93 = styled.div`
  position: absolute;
  height: calc(100% - 18.7px);
  width: calc(100% - 470.3px);
  top: 0px;
  right: 71.7px;
  bottom: 18.7px;
  left: 398.6px;
  border-radius: 2.79px;
 
`;
const Div94 = styled.div`
  position: absolute;
  height: calc(100% - 18.7px);
  width: calc(100% - 472.2px);
  top: 0px;
  right: 91.3px;
  bottom: 18.7px;
  left: 380.9px;
  border-radius: 2.79px;
 
`;
const Div95 = styled.div`
  position: absolute;
  height: calc(100% - 18.7px);
  width: calc(100% - 452.6px);
  top: 18.6px;
  right: 71.7px;
  bottom: 0.1px;
  left: 380.9px;
  border-radius: 2.79px;
 
`;
const Div96 = styled.div`
  position: absolute;
  height: calc(100% - 18.7px);
  width: calc(100% - 452.6px);
  top: 0px;
  right: 0px;
  bottom: 18.7px;
  left: 452.6px;
  border-radius: 2.79px;
 
`;
const Div97 = styled.div`
  position: absolute;
  height: calc(100% - 18.7px);
  width: calc(100% - 452.6px);
  top: 18.6px;
  right: 0px;
  bottom: 0.1px;
  left: 452.6px;
  border-radius: 2.79px;
 
`;
const Parent6 = styled.div`
  position: absolute;
  top: calc(50% + 199.2px);
  left: calc(50% - 70.1px);
  width: 487.1px;
  height: 34.5px;
  overflow: hidden;
`;
const Div98 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 417.2px);
  top: 0px;
  right: 308.2px;
  bottom: 0px;
  left: 109px;
  border-radius: 2.79px;
 
`;
const Div99 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 417.2px);
  top: 0px;
  right: 162.9px;
  bottom: 0px;
  left: 254.3px;
  border-radius: 2.79px;
 
`;
const Div100 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 454.5px);
  top: 0px;
  right: 91.3px;
  bottom: 0px;
  left: 363.2px;
  border-radius: 2.79px;
 
`;
const Div101 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 455.4px);
  top: 0px;
  right: 37.2px;
  bottom: 0px;
  left: 418.2px;
  border-radius: 2.79px;
 
`;
const Div102 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 470.3px);
  top: 0px;
  right: 17.7px;
  bottom: 0px;
  left: 452.6px;
  border-radius: 2.79px;
 
`;
const Div103 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 472.2px);
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 472.2px;
  border-radius: 2.79px;
 
`;
const Div104 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 470.3px);
  top: 0px;
  right: 71.7px;
  bottom: 0px;
  left: 398.6px;
  border-radius: 2.79px;
 
`;
const Div105 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 452.6px);
  top: 0px;
  right: 235.6px;
  bottom: 0px;
  left: 217px;
  border-radius: 2.79px;
 
`;
const Parent7 = styled.div`
  position: absolute;
  top: calc(50% + 260.7px);
  left: calc(50% - 70.1px);
  width: 487.1px;
  height: 17.7px;
  overflow: hidden;
`;
const Div106 = styled.div`
  position: absolute;
  height: calc(100% - 45.6px);
  width: 100%;
  top: 45.6px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div107 = styled.div`
  position: absolute;
  height: calc(100% - 134.1px);
  width: 100%;
  top: 24.2px;
  right: 0px;
  bottom: 109.9px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div108 = styled.div`
  position: absolute;
  height: calc(100% - 134.1px);
  width: 100%;
  top: 2.8px;
  right: 0px;
  bottom: 131.3px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Parent8 = styled.div`
  position: absolute;
  top: calc(50% + 69.7px);
  left: calc(50% + 446.8px);
  width: 56.8px;
  height: 152.7px;
  overflow: hidden;
`;
const Div109 = styled.div`
  position: absolute;
  height: calc(100% - 218.9px);
  width: 100%;
  top: 218.9px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div110 = styled.div`
  position: absolute;
  height: calc(100% - 217.9px);
  width: 100%;
  top: 196.5px;
  right: 0px;
  bottom: 21.4px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div111 = styled.div`
  position: absolute;
  height: calc(100% - 217.9px);
  width: 100%;
  top: 174.2px;
  right: 0px;
  bottom: 43.7px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div112 = styled.div`
  position: absolute;
  height: calc(100% - 218.9px);
  width: 100%;
  top: 152.7px;
  right: 0px;
  bottom: 66.2px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div113 = styled.div`
  position: absolute;
  height: calc(100% - 217.9px);
  width: 100%;
  top: 130.4px;
  right: 0px;
  bottom: 87.5px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div114 = styled.div`
  position: absolute;
  height: calc(100% - 219.8px);
  width: 100%;
  top: 109.9px;
  right: 0px;
  bottom: 109.9px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div115 = styled.div`
  position: absolute;
  height: calc(100% - 217.9px);
  width: 100%;
  top: 87.5px;
  right: 0px;
  bottom: 130.4px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div116 = styled.div`
  position: absolute;
  height: calc(100% - 218.9px);
  width: 100%;
  top: 66.1px;
  right: 0px;
  bottom: 152.8px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div117 = styled.div`
  position: absolute;
  height: calc(100% - 217.9px);
  width: 100%;
  top: 43.8px;
  right: 0px;
  bottom: 174.1px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div118 = styled.div`
  position: absolute;
  height: calc(100% - 217.9px);
  width: 100%;
  top: 21.4px;
  right: 0px;
  bottom: 196.5px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div119 = styled.div`
  position: absolute;
  height: calc(100% - 218.9px);
  width: calc(100% + 1.8px);
  top: 0px;
  right: 0.1px;
  bottom: 218.9px;
  left: -1.9px;
  border-radius: 2.79px;
 
`;
const Parent9 = styled.div`
  position: absolute;
  top: calc(50% + 47.4px);
  left: calc(50% - 179.1px);
  width: 23.3px;
  height: 237.5px;
  overflow: hidden;
`;
const Div120 = styled.div`
  position: absolute;
  height: calc(100% - 163.9px);
  width: 100%;
  top: 163.9px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div121 = styled.div`
  position: absolute;
  height: calc(100% - 163.9px);
  width: 100%;
  top: 146.2px;
  right: 0px;
  bottom: 17.7px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div122 = styled.div`
  position: absolute;
  height: calc(100% - 162px);
  width: 100%;
  top: 126.7px;
  right: 0px;
  bottom: 35.3px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div123 = styled.div`
  position: absolute;
  height: calc(100% - 163.9px);
  width: 100%;
  top: 109px;
  right: 0px;
  bottom: 54.9px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div124 = styled.div`
  position: absolute;
  height: calc(100% - 163.9px);
  width: 100%;
  top: 91.3px;
  right: 0px;
  bottom: 72.6px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div125 = styled.div`
  position: absolute;
  height: calc(100% - 163px);
  width: 100%;
  top: 72.6px;
  right: 0px;
  bottom: 90.4px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div126 = styled.div`
  position: absolute;
  height: calc(100% - 163.9px);
  width: 100%;
  top: 54.9px;
  right: 0px;
  bottom: 109px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div127 = styled.div`
  position: absolute;
  height: calc(100% - 162px);
  width: 100%;
  top: 35.4px;
  right: 0px;
  bottom: 126.6px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div128 = styled.div`
  position: absolute;
  height: calc(100% - 163.9px);
  width: 100%;
  top: 17.7px;
  right: 0px;
  bottom: 146.2px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div129 = styled.div`
  position: absolute;
  height: calc(100% - 163.9px);
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 163.9px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Parent10 = styled.div`
  position: absolute;
  top: calc(50% + 64.1px);
  left: calc(50% - 293.7px);
  width: 16.8px;
  height: 178.8px;
  overflow: hidden;
`;
const Div130 = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Wrapper = styled.div`
  position: absolute;
  top: calc(50% - 109.1px);
  left: calc(50% - 293.7px);
  width: 18.6px;
  height: 150.9px;
  overflow: hidden;
`;
const Div131 = styled.div`
  position: absolute;
  height: calc(100% - 153.7px);
  width: 100%;
  top: 120.1px;
  right: 0px;
  bottom: 33.6px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div132 = styled.div`
  position: absolute;
  height: calc(100% - 154.6px);
  width: 100%;
  top: 103.4px;
  right: 0px;
  bottom: 51.2px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div133 = styled.div`
  position: absolute;
  height: calc(100% - 154.6px);
  width: 100%;
  top: 86.6px;
  right: 0px;
  bottom: 68px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div134 = styled.div`
  position: absolute;
  height: calc(100% - 152.8px);
  width: 100%;
  top: 68px;
  right: 0px;
  bottom: 84.8px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div135 = styled.div`
  position: absolute;
  height: calc(100% - 154.6px);
  width: 100%;
  top: 51.2px;
  right: 0px;
  bottom: 103.4px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div136 = styled.div`
  position: absolute;
  height: calc(100% - 153.7px);
  width: 100%;
  top: 33.5px;
  right: 0px;
  bottom: 120.2px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div137 = styled.div`
  position: absolute;
  height: calc(100% - 154.6px);
  width: 100%;
  top: 16.8px;
  right: 0px;
  bottom: 137.8px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Parent11 = styled.div`
  position: absolute;
  top: calc(50% + 74.4px);
  left: calc(50% - 254.5px);
  width: 18.6px;
  height: 168.6px;
  overflow: hidden;
`;
const Div138 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 163.9px);
  top: 0px;
  right: 163.9px;
  bottom: 0px;
  left: 0px;
  border-radius: 2.79px;
 
`;
const Div139 = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 181.6px);
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 181.6px;
  border-radius: 2.79px;
 
`;
const Div140 = styled.div`
  position: absolute;
  height: calc(100% - 36.4px);
  width: calc(100% - 218.9px);
  top: 36.3px;
  right: 127.6px;
  bottom: 0.1px;
  left: 91.3px;
  border-radius: 2.79px;
 
`;
const Div141 = styled.div`
  position: absolute;
  height: calc(100% - 36.4px);
  width: calc(100% - 218px);
  top: 0px;
  right: 109px;
  bottom: 36.4px;
  left: 109px;
  border-radius: 2.79px;
 
`;
const Div142 = styled.div`
  position: absolute;
  height: calc(100% - 36.4px);
  width: calc(100% - 218.9px);
  top: 0px;
  right: 127.6px;
  bottom: 36.4px;
  left: 91.3px;
  border-radius: 2.79px;
 
`;
const Div143 = styled.div`
  position: absolute;
  height: calc(100% - 36.4px);
  width: calc(100% - 218.9px);
  top: 18.6px;
  right: 127.6px;
  bottom: 17.8px;
  left: 91.3px;
  border-radius: 2.79px;
 
`;
const Div144 = styled.div`
  position: absolute;
  height: calc(100% - 36.4px);
  width: calc(100% - 217px);
  top: 0px;
  right: 71.7px;
  bottom: 36.4px;
  left: 145.3px;
  border-radius: 2.79px;
 
`;
const Div145 = styled.div`
  position: absolute;
  height: calc(100% - 36.4px);
  width: calc(100% - 218px);
  top: 36.3px;
  right: 91.3px;
  bottom: 0.1px;
  left: 126.7px;
  border-radius: 2.79px;
 
`;
const Div146 = styled.div`
  position: absolute;
  height: calc(100% - 36.4px);
  width: calc(100% - 218.9px);
  top: 0px;
  right: 91.3px;
  bottom: 36.4px;
  left: 127.6px;
  border-radius: 2.79px;
 
`;
const Div147 = styled.div`
  position: absolute;
  height: calc(100% - 36.4px);
  width: calc(100% - 218px);
  top: 36.3px;
  right: 109px;
  bottom: 0.1px;
  left: 109px;
  border-radius: 2.79px;
 
`;
const Div148 = styled.div`
  position: absolute;
  height: calc(100% - 36.4px);
  width: calc(100% - 218px);
  top: 36.3px;
  right: 72.7px;
  bottom: 0.1px;
  left: 145.3px;
  border-radius: 2.79px;
 
`;
const Parent12 = styled.div`
  position: absolute;
  top: calc(50% + 12px);
  left: calc(50% + 183.2px);
  width: 233.8px;
  height: 52.2px;
  overflow: hidden;
`;
const Div149 = styled.div`
  position: absolute;
  top: calc(50% - 97px);
  left: calc(50% - 180px);
  border-radius: 2.79px;
 
  width: 60.5px;
  height: 122px;
`;
const Div150 = styled.div`
  position: absolute;
  top: calc(50% - 145.4px);
  left: calc(50% + 446.8px);
  border-radius: 2.79px;
 
  width: 55.9px;
  height: 115.5px;
`;

const Div151 = styled.div`
  position: absolute;
  top: calc(50% - 206.9px);
  left: calc(50% - 294.6px);
  border-radius: 2.79px;
 
  width: 67.1px;
  height: 58.7px;
`;
const FrameContainer = styled.div`
  width: 100%;
  position: absolute;
  margin: 0 !important;
  height: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 1;
`;
const FrameItem = styled.img`
  width: 1004px;
  position: absolute;
  margin: 0 !important;
  top: calc(50% - 246.4px);
  left: calc(50% - 359px);
  height: 558.8px;
  object-fit: cover;
  z-index: 0;
`;
const RectangleWrapper = styled.div`
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-mini);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;
const FimmapInner = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  z-index: 0;
`;
const DetailanRoot = styled(motion.div)`
  width: 1366px;
  background-color: var(--third);
  max-width: 100%;
  height: 768px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  text-align: center;
  font-size: 12.5px;
  color: var(--white);
  font-family: var(--paragraph);
`;

const Icon = styled.img`
  margin: 0 !important;
  border-radius: var(--br-6xs-7);
  height: 76.4px;
  width: auto;
  object-fit: contain;
  background-color: white
`;

const container = {

    hidden: {opacity:0},
    show: {
      opacity:1,
  
      transition:{
        staggerChildren: 0.2,
        duration: 0.5,
      }
    }
  
  }

const Main = () => {
    const [categories, setCategories] = useState([])
    const [categorie, setCategorie] = useState("all")
    const [input, setInput] = useState('all')
    const [companies, setCompanies] = useState([])
    const [company, setCompany] = useState([])
    const [id, setId] = useState(0)

    useEffect(() => {
        window.scrollTo(0,0)
        getCategoriesFromApi().then((data)=>setCategories(data))
        getCompaniesFromApi(categorie, 'all').then((data)=>{
          if(data !== undefined){
              setCompanies(data)
          }
        })
    }, []);

    const isJSONString = (str) => {
        try {
          JSON.parse(str);
          return true;
        } catch (e) {
          return false;
        }
      }

      const updateResult = (categorie, input) => {
          setCategorie(categorie)
          setInput(input)
          getCompaniesFromApi(categorie, input).then((data)=>{
              if(data !== undefined){
                  setCompanies(data)
              }
          })
      }

      function getDigitPart(id) {
        return id.match(/\d+/)[0];
      }

      console.log(companies)

      useEffect(() => {
        // Parse the index values from the data
        const indexValues = companies.flatMap(item => item.key.split(", ").map(Number));
        
        // Get all div elements with class "stand"
        const standDivs = document.querySelectorAll(".stand");

        standDivs.forEach(div => {
          const index = div.getAttribute("id");
          const digitPart = getDigitPart(index);
          // console.log(digitPart)
          
          // Change the color of div if its digit part is in the index values
          if (indexValues.includes(Number(digitPart))) {
            div.style.backgroundColor = "var(--primary)";
          } else {
            div.style.backgroundColor = "#999";
          }
        });
      }, [companies]);

  return (
    <>
        <DetailanRoot
            variants={container}
            initial='hidden'
            animate='show'
            exit={{
                opacity:0, transition:{duration: 0.5}
            }}
        >
        <WelcomeSection>
          <FrameParent>
            <FrameChild />
            <FrameWrapper>
                <input
                    placeholder="Mots clés"
                    type="text"
                    style={{
                        padding: "var(--padding-mini)",
                        width: "100%",
                        height: "100%",
                    }}
                    onChange={(v)=>{
                            if((v.target.value).replace(" ", "") === ""){
                                updateResult(categorie, "all")
                            }else{
                                updateResult(categorie, v.target.value)
                            }
                        }
                    }
                />
                <GoWrapper
                    onClick={()=>{
                        updateResult(categorie, input)
                    }}
                >
                  <Go>GO</Go>
                </GoWrapper>
            </FrameWrapper>
          </FrameParent>
          <WelcomeSectionChild />
          <FrameGroup className="exhibitors">
              {
                    companies.map((item, id) => {
                        return(
                          <SloganDeLaFoireInternationParent
                            onClick={()=>{
                              setId(item.key="")
                            }}
                          >
                            <SloganDeLa>
                              {item.name}
                            </SloganDeLa>
                            <Stands>Stands : {item.stands}</Stands>
                          </SloganDeLaFoireInternationParent>
                        )
                    })
              }
          </FrameGroup>
        </WelcomeSection>
        <FimmapChild />
        <WelcomeSection1 className="sectors">
          <LeadershipWrapper
            onClick={()=>{
              updateResult("all", input)
            }}
          >
            <Leadership>Tout</Leadership>
          </LeadershipWrapper>
          {
            categories.map((item, id)=>{
              return(
                  <LeadershipWrapper
                    style={{
                      borderLeft: "solid 30px"+ item.color
                    }}
                    onClick={()=>{
                      updateResult(item.name, input)
                    }}
                  >
                      <Leadership>{item.name}</Leadership>
                  </LeadershipWrapper>
              )
            })
          }
        </WelcomeSection1>
        <FrameContainer>
          <Parent1>
            <Div className="stand" id="st1"/>
            <Div1 className="stand" id="st2"/>
            <Div2 className="stand" id="st3"/>
            <Div3 className="stand" id="st4"/>
            <Div4 className="stand" id="st5"/>
            <Div5 className="stand" id="st6"/>
            <Div6 className="stand" id="st7"/>
            <Div7 className="stand" id="st8"/>
          </Parent1>
          <Group>
            <Div8 className="stand" id="st9"/>
            <Div9 className="stand" id="st10"/>
            <Div10 className="stand" id="st11"/>
            <Div11 className="stand" id="st12"/>
            <Div12 className="stand" id="st13"/>
            <Div13 className="stand" id="st14"/>
            <Div14 className="stand" id="st15"/>
            <Div15 className="stand" id="st16"/>
            <Div16 className="stand" id="st17"/>
            <Div17 className="stand" id="st18"/>
            <Div18 className="stand" id="st19"/>
            <Div19 className="stand" id="st20"/>
            <Div20 className="stand" id="st21"/>
            <Div21 className="stand" id="st22"/>
            <Div22 className="stand" id="st23"/>
            <Div23 className="stand" id="st24"/>
          </Group>
          <Container>
            <Div24 className="stand" id="st25"/>
            <Div25 className="stand" id="st26"/>
            <Div26 className="stand" id="st27"/>
            <Div27 className="stand" id="st28"/>
            <Div28 className="stand" id="st29"/>
            <Div29 className="stand" id="st30"/>
            <Div30 className="stand" id="st31"/>
            <Div31 className="stand" id="st32"/>
            <Div32 className="stand" id="st33"/>
            <Div16 className="stand" id="st34"/>
            <Div33 className="stand" id="st35"/>
            <Div22 className="stand" id="st36"/>
            <Div34 className="stand" id="st37"/>
            <Div35 className="stand" id="st38"/>
            <Div36 className="stand" id="st39"/>
            <Div37 className="stand" id="st40"/>
            <Div38 className="stand" id="st41"/>
            <Div39 className="stand" id="st42"/>
            <Div40 className="stand" id="st43"/>
            <Div41 className="stand" id="st44"/>
            <Div42 className="stand" id="st45"/>
            <Div43 className="stand" id="st46"/>
            <Div44 className="stand" id="st47"/>
            <Div45 className="stand" id="st48"/>
            <Div19 className="stand" id="st49"/>
            <Div46 className="stand" id="st50"/>
            <Div47 className="stand" id="st51"/>
            <Div48 className="stand" id="st52"/>
            <Div49 className="stand" id="st53"/>
            <Div50 className="stand" id="st54"/>
          </Container>
          <Parent2>
            <Div51 className="stand" id="st55"/>
            <Div52 className="stand" id="st56"/>
            <Div53 className="stand" id="st57"/>
            <Div54 className="stand" id="st58"/>
            <Div42 className="stand" id="st59"/>
            <Div44 className="stand" id="st60"/>
            <Div55 className="stand" id="st61"/>
            <Div56 className="stand" id="st62"/>
            <Div57 className="stand" id="st63"/>
            <Div9 className="stand" id="st64"/>
            <Div58 className="stand" id="st65"/>
            <Div59 className="stand" id="st66"/>
            <Div60 className="stand" id="st67"/>
            <Div61 className="stand" id="st68"/>
            <Div62 className="stand" id="st69"/>
            <Div25 className="stand" id="st70"/>
          </Parent2>
          <Parent3>
            <Div63 className="stand" id="st71"/>
            <Div64 className="stand" id="st72"/>
            <Div65 className="stand" id="st73"/>
          </Parent3>
          <Parent4>
            <Div66 className="stand" id="st74"/>
            <Div67 className="stand" id="st75"/>
            <Div68 className="stand" id="st76"/>
            <Div69 className="stand" id="st77"/>
            <Div25 className="stand" id="st78"/>
            <Div70 className="stand" id="st79"/>
            <Div71 className="stand" id="st80"/>
            <Div72 className="stand" id="st81"/>
            <Div58 className="stand" id="st82"/>
            <Div73 className="stand" id="st83"/>
            <Div56 className="stand" id="st84"/>
            <Div22 className="stand" id="st85"/>
            <Div23 className="stand" id="st86"/>
            <Div74 className="stand" id="st87"/>
            <Div53 className="stand" id="st88"/>
            <Div54 className="stand" id="st89"/>
            <Div43 className="stand" id="st90"/>
            <Div75 className="stand" id="st91"/>
            <Div45 className="stand" id="st92"/>
            <Div51 className="stand" id="st93"/>
            <Div47 className="stand" id="st94"/>
            <Div49 className="stand" id="st95"/>
            <Div21 className="stand" id="st96"/>
          </Parent4>
          <Parent5>
            <Div66 className="stand" id="st97"/>
            <Div68 className="stand" id="st98"/>
            <Div67 className="stand" id="st99"/>
            <Div76 className="stand" id="st100"/>
            <Div77 className="stand" id="st101"/>
            <Div31 className="stand" id="st102"/>
            <Div30 className="stand" id="st103"/>
            <Div78 className="stand" id="st104"/>
            <Div32 className="stand" id="st105"/>
            <Div73 className="stand" id="st106"/>
            <Div79 className="stand" id="st107"/>
            <Div22 className="stand" id="st108"/>
            <Div80 className="stand" id="st109"/>
            <Div81 className="stand" id="st110"/>
            <Div46 className="stand" id="st111"/>
            <Div19 className="stand" id="st112"/>
            <Div52 className="stand" id="st113"/>
          </Parent5>
          <Parent6>
            <Div82 className="stand" id="st114"/>
            <Div83 className="stand" id="st115"/>
            <Div84 className="stand" id="st116"/>
            <Div85 className="stand" id="st117"/>
            <Div86 className="stand" id="st118"/>
            <Div87 className="stand" id="st119"/>
            <Div88 className="stand" id="st120"/>
            <Div89 className="stand" id="st121"/>
            <Div90 className="stand" id="st122"/>
            <Div91 className="stand" id="st123"/>
            <Div92 className="stand" id="st124"/>
            <Div93 className="stand" id="st125"/>
            <Div94 className="stand" id="st126"/>
            <Div95 className="stand" id="st127"/>
            <Div51 className="stand" id="st128"/>
            <Div96 className="stand" id="st129"/>
            <Div97 className="stand" id="st130"/>
          </Parent6>
          <Parent7>
            <Div8 className="stand" id="st131"/>
            <Div98 className="stand" id="st132"/>
            <Div99 className="stand" id="st133"/>
            <Div100 className="stand" id="st134"/>
            <Div101 className="stand" id="st135"/>
            <Div102 className="stand" id="st136"/>
            <Div103 className="stand" id="st137"/>
            <Div104 className="stand" id="st138"/>
            <Div105 className="stand" id="st139"/>
          </Parent7>
          <Parent8>
            <Div106 className="stand" id="st140"/>
            <Div107 className="stand" id="st141"/>
            <Div108 className="stand" id="st142"/>
          </Parent8>
          <Parent9>
            <Div109 className="stand" id="st143"/>
            <Div110 className="stand" id="st144"/>
            <Div111 className="stand" id="st145"/>
            <Div112 className="stand" id="st146"/>
            <Div113 className="stand" id="st147"/>
            <Div114 className="stand" id="st148"/>
            <Div115 className="stand" id="st149"/>
            <Div116 className="stand" id="st150"/>
            <Div117 className="stand" id="st151"/>
            <Div118 className="stand" id="st152"/>
            <Div119 className="stand" id="st153"/>
          </Parent9>
          <Parent10>
            <Div120 className="stand" id="st154"/>
            <Div121 className="stand" id="st155"/>
            <Div122 className="stand" id="st156"/>
            <Div123 className="stand" id="st157"/>
            <Div124 className="stand" id="st158"/>
            <Div125 className="stand" id="st159"/>
            <Div126 className="stand" id="st160"/>
            <Div127 className="stand" id="st161"/>
            <Div128 className="stand" id="st162"/>
            <Div129 className="stand" id="st163"/>
          </Parent10>
          <Wrapper>
            <Div130 className="stand" id="st164"/>
          </Wrapper>
          <Parent11>
            <Div131 className="stand" id="st165"/>
            <Div132 className="stand" id="st166"/>
            <Div133 className="stand" id="st167"/>
            <Div134 className="stand" id="st168"/>
            <Div135 className="stand" id="st169"/>
            <Div136 className="stand" id="st170"/>
            <Div137 className="stand" id="st171"/>
          </Parent11>
          <Parent12>
            <Div138 className="stand" id="st172"/>
            <Div139 className="stand" id="st173"/>
            <Div140 className="stand" id="st174"/>
            <Div141 className="stand" id="st175"/>
            <Div142 className="stand" id="st176"/>
            <Div143 className="stand" id="st177"/>
            <Div144 className="stand" id="st178"/>
            <Div145 className="stand" id="st179"/>
            <Div146 className="stand" id="st180"/>
            <Div147 className="stand" id="st181"/>
            <Div148 className="stand" id="st182"/>
          </Parent12>
          <Div149 className="stand" id="st183"/>
          <Div150 className="stand" id="st184"/>
          <Icon alt="" src="/185.svg" className="stand" id="st185"/>
          <Div151 className="stand" id="st186"/>
        </FrameContainer>
        <FimmapInner>
          <RectangleWrapper>
            <FrameItem alt="" src="/rectangle-12@2x.png" />
          </RectangleWrapper>
        </FimmapInner>
        </DetailanRoot>
    </>
  );
};

export default Main;
